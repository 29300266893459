import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApPageService {


    list_page() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_page/list_page", form);

    }
    
    view_page(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_page/view_page", form);

    }

    insert_page(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_page/insert_page", form);

    }

    update_page(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_page/update_page", form);

    }    
}
    
 

export default new ApPageService();